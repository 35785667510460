@font-face {
	font-family: minedotpink;
	src: url("/fonts/minedotpink.woff");
}

@font-face {
	font-family: apple;
	src: url("/fonts/apple.woff");
}

@media screen and (max-width: 500px) {
	
	body {-webkit-text-size-adjust: none;}

	div.blit {
		width: calc(100vmin * 1/3) !important;
		height: auto;
		display: block;
		position: relative;
		margin-bottom: 3.3333333333333335vmin;
		background-color: black;
	}
	
	p.blit {
		font-size: 2vmin !important;
		line-height: 2vmin !important;
		text-align: center;
	}
	
	div.shots {
		padding-left: calc(50% - 30vmin) !important;
		padding-right: calc(50% - 30vmin) !important;
	}
	
	img.shot {
		width: 60vmin !important;
	}
	
}

html {
	min-height: fill-available;
	min-height: -webkit-fill-available;
	min-height: -moz-available;
}

body.black {
	background-color: black !important;
}

.Landing {
	-webkit-tap-highlight-color: transparent;
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	line-height: 0;
	position: relative;
	text-align: left;
	color: black;
	cursor: url(/files/default.svg) 0 0, default !important;





	.c {text-align: center;}
	
	.blue {color: #46C1F3;}
	.tone {color: #CCD5E0;}
	
	.red {color: #F11922;}
	.pink {color: #F7B5E3;}
	
	.silver {color: #B8C0D9;}
	.tone {color: #CCD5E0;}
	
	.w100 {
		width: 100vmin;
		margin: 0 auto;
	}


	p {
		font-family: minedotpink;
		overflow-wrap: break-word;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	
	a {
		font-family: minedotpink;
		overflow-wrap: break-word;
		margin: 0;
		padding: 0;
		display: block;
		text-decoration: none;
		cursor: url(/files/pointer.svg) 13 3, pointer !important;
	}
	
	img {
		-webkit-user-select: none !important;
		-webkit-touch-callout: none !important;
		-webkit-user-drag: none !important;
		user-drag: none !important;
	}




	.button {
		width: 100%;
		height: calc(100vmin * 1/3);
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}

	.collect {
		color: #FF007C;
		background-color: #A4A7A3;
	}
	
	.beta {
		color: #016BC7;
		background-color: #40C2FB;
	}
	
	.apple {
		font-family: apple;
		font-size: 15vmin;
		margin-right: 1.5vmin;
	}
	
	.tap:active {
		opacity: 0.25;
	}
	

	
	.bigger {
		font-size: 8vmin;
		line-height: 9.5vmin;
		letter-spacing: -0.25vmin;
	}	
	
	hr.x125 {height: calc(100vmin * 1/3);}
	
	.icon {
		width: calc(100vmin * 1/3);
		height: calc(100vmin * 1/3);
		margin: 0 auto;
		display: block;
	}
	
	
	

	
	
	
	
	.example {
		width: 100vmin;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}
	
	.icon {
		width: 35vmin;
		height: 35vmin;
		height: auto;
		position: relative;
		margin-left: 7.5vmin;
		margin-right: 5vmin;
	}
		
	.screen {
		width: 50vmin;
		height: auto;
		position: relative;
	}
	
	
	
	
	

	.whitebg {background-color: #FFFFFF;}
	


	
	
	
	hr {
		height: 6.666666666666667vmin;
		border: none;
		margin: 0;
	}
	
	hr.h25 {
		height: 6.666666666666667vmin;
	}
	
	hr.h50 {
		height: 13.333333333333334vmin;
	}
	
	hr.h75 {
		height: 20vmin;
	}
	
	hr.h125 {
		height: calc(100vmin * 1/3);
	}
	



	
	





	.x100 {
		font-size: 3.333vmin;
		line-height: 4vmin;
	}
	.x125 {
		font-size: 4.168vmin;
		line-height: 5vmin;
		letter-spacing: -0.06666666666666667vmin;
	}
	.x150 {
		font-size: 5vmin;
		line-height: 6vmin;
		letter-spacing: -0.06666666666666667vmin;
	}
	.x175 {
		font-size: 5.834666666666666vmin;
		line-height: 7.000000000000001vmin;
		letter-spacing: -0.13333333333333333vmin;
	}
	.x200 {
		font-size: 6.666vmin;
		line-height: 8vmin;
		letter-spacing: -0.13333333333333333vmin;
	}
	.x225 {
		font-size: 7.501333333333333vmin;
		line-height: 9vmin;
		letter-spacing: -0.2vmin;
	}
	.x250 {
		font-size: 8.333333333333332vmin;
		line-height: 10vmin;
		letter-spacing: -0.2vmin;
	}
	.x300 {
		font-size: 10vmin;
		line-height: 12vmin;
		letter-spacing: -0.26666666666666666vmin;
	}
	.x350 {
		font-size: 11.666666666666666vmin;
		line-height: 14.000000000000002vmin;
		letter-spacing: -0.33333333333333337vmin;
	}
	.x400 {
		font-size: 13.333333333333334vmin;
		line-height: 16vmin;
		letter-spacing: -0.4vmin;
	}
	.x450 {
		font-size: 15vmin;
		line-height: 18vmin;
		letter-spacing: -0.46666666666666673vmin;
	}
	
	
	
	
	
	
	
	
	
	
	
	div.shot {
		width: 100%;
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		position: relative;
	}
	
	div.shots {
		display: flex;
		position: relative;
		padding-left: calc(50% - 25vmin);
		padding-right: calc(50% - 25vmin);
	}
	
	img.shot {
		flex: 0 0 auto;
		width: 50vmin;
		height: auto;
	}

	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	.inline {display: inline;}
	
	.intro {
		width: 100%;
		color: #030302;
		background-color: #999999;
		background-color: #A4A7A3;
	}
	
	.smaller {
		color: #E6E6E6;
	}
	
	p.blits {
		width: 100vmin;
		margin: 0 auto;
	}

	a.blits {
		color: #FF007C;
	}
	
	a.blits:active {
		color: #030302;
	}

	.nano {
		position: absolute;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-content: center;
		z-index: 5;
		background-color: black;
		cursor: url(/files/pointer.svg) 13 3, pointer !important;
	}
	
	.nano:active {
		display: none;
	}
	
	.og {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-content: center;
		cursor: url(/files/pointer.svg) 13 3, pointer !important;
	}
	
	div.blit {
		width: calc(100vmin * 1/5);
		height: auto;
		display: block;
		position: relative;
		margin-bottom: 3.5vmin;
	}
	
	img.blit {
		width: 75%;
		padding: 12.5%;
	}
	
	p.blit {
		font-size: 1vmin;
		line-height: 1vmin;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-align: center;
		overflow: visible;
	}
	
	.n0 {color: #1500FF;}
	.n1 {color: #63C84D;}
	.n2 {color: #4F6781;}
	.n3 {color: #FFFFFF;}
	.n4 {color: #BFB4F8;}
	.n5 {color: #FF0044;}
	.n6 {color: #A0A840;}
	.n7 {color: #FF55FF;}
	.n8 {color: #A22633;}
	
	.n9 {color: #D77643;}
	.n10 {color: #DAD45D;}
	.n11 {color: #5FCDE4;}
	.n12 {color: #F6757A;}
	.n13 {color: #FF0044;}
	.n14 {color: #F7B58C;}
	.n15 {color: #E04F88;}
	.n16 {color: #FFF6D3;}
	.n17 {color: #2BE8F5;}
	
	.n18 {color: #A3CE27;}
	.n19 {color: #124E89;}
	.n20 {color: #FF0044;}
	.n21 {color: #C0CBDC;}
	.n22 {color: #FCE4A8;}
	.n23 {color: #9D9D9D;}
	.n24 {color: #6DAA2B;}
	.n25 {color: #2BE8F5;}
	.n26 {color: #FEFF55;}
	
	.n27 {color: #FFC96B;}
	.n28 {color: #F42E20;}
	.n29 {color: #FC813A;}
	.n30 {color: #3B9C69;}
	.n31 {color: #5F658E;}
	.n32 {color: #912565;}
	.n33 {color: #A14930;}
	.n34 {color: #9DD9D6;}
	.n35 {color: #65FFE1;}
	
	.n36 {color: #DE3640;}
	.n37 {color: #FC813A;}
	.n38 {color: #A14A30;}
	.n39 {color: #7886CA;}
	.n40 {color: #AEB42A;}
	.n41 {color: #FEE0B2;}
	.n42 {color: #00FF84;}
	.n43 {color: #CB0097;}
	.n44 {color: #0B28D0;}
	
	.n45 {color: #E8A4F8;}
	.n46 {color: #378453;}
	.n47 {color: #C75B22;}
	.n48 {color: #CC4442;}
	.n49 {color: #FFDA54;}
	.n50 {color: #31D55F;}
	.n51 {color: #5E31A4;}
	.n52 {color: #FDF151;}
	.n53 {color: #3F50B5;}
	
	.n54 {color: #AE8484;}
	.n55 {color: #82522C;}
	.n56 {color: #A7A7A7;}
	.n57 {color: #685E44;}
	.n58 {color: #FB00FF;}
	.n59 {color: #98834A;}
	.n60 {color: #843839;}
	.n61 {color: #E7D24D;}
	.n62 {color: #2773A9;}
	
	.n63 {color: #26BE1A;}
	.n64 {color: #2773A9;}
	.n65 {color: #FE4CFF;}
	.n66 {color: #159A01;}
	.n67 {color: #D3A46E;}
	.n68 {color: #AA6565;}
	.n69 {color: #E5ACB2;}
	.n70 {color: #5E6844;}
	.n71 {color: #5D5D5D;}
	
	.n72 {color: #CECECE;}
	.n73 {color: #868C65;}
	.n74 {color: #FF0001;}
	.n75 {color: #6AE157;}
	.n76 {color: #7D5A0A;}
	.n77 {color: #FF5E57;}
	.n78 {color: #EBEBEB;}
	.n79 {color: #A7A7A7;}
	.n80 {color: #A97441;}
	
	.n81 {color: #274BFB;}
	.n82 {color: #FFEC3A;}
	.n83 {color: #AFBCBA;}
	.n84 {color: #01FF02;}
	.n85 {color: #FCFCFC;}
	.n86 {color: #CF3559;}
	.n87 {color: #C2D060;}
	.n88 {color: #89D0B7;}
	.n89 {color: #00FFA8;}
	
	.n90 {color: #9271C0;}
	.n91 {color: #AFBFD2;}
	.n92 {color: #2BE8F4;}
	.n93 {color: #50A16C;}
	.n94 {color: #B86F50;}
	.n95 {color: #E1FC9C;}
	.n96 {color: #1F6FBF;}
	.n97 {color: #BB4CFB;}
	.n98 {color: #940B0F;}
	.n99 {color: #92BDA0;}
	
	.n100 {color: #FFFFFF;}
	.n101 {color: #DAFF00;}
	.n102 {color: #FF0040;}
	.n103 {color: #062FB7;}
	
	
		
}