@font-face {
  font-family: display;
  src: url('/fonts/display.woff'); }

@font-face {
  font-family: rounded;
  src: url('/fonts/rounded.woff'); }

body {
  margin: 0;
  font-family: display, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (prefers-color-scheme: dark) {
    background-color: #000; } }



/*  App  */

.App {
  display: flex;
  text-align: center; }

/*  Space  */

.Space {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin: 0 auto;
  overflow: hidden;
  width: 100vmin;
  align-items: flex-end;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  text-align: center; }

/*  Blocks  */

.block {
  flex-grow: 0;

  // small size - square pics
  &.size-0 {
    flex-basis: 33.33%;
    .make-it-square {
      margin-top: 100%;
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100.1%; // .1% fixes white pixel line between 1/3 and full images in Safari (a bug with object-fit: cover)
        object-fit: cover; } } }

  // large size - full pics
  &.size-1 {
    flex-basis: 100%;
    img {
      object-fit: contain;
      width: 100vmin;
      height: auto; } } }


.PhotoBlock {
  font-size: 0;
  line-height: 0;
  position: relative;
  cursor: pointer; }

.VideoBlock {
  font-size: 0;
  line-height: 0;
  position: relative;
  cursor: pointer;

  .FrameAnimation {
    img {
      opacity: 0;
      &.visible-frame {
        opacity: 1; } } }

  &.size-1 {
    .FrameAnimation {
      // large size - frames overlay each other
      // (solved for small size by make-it-square logic)
      img {
        margin-top: -100%;
        &:first-child {
          margin-top: 0; } } } } }



.TextBlock {
  flex-basis: 100% !important;
  max-width: 100%;
  overflow-wrap: break-word;
  z-index: 1;
  padding: 0 2.5vmin;
  box-sizing: border-box;

  @media (prefers-color-scheme: dark) {
    color: #fff; }

  // --- Font settings ---

  // Large size
  &.size-1 {
    font-size: 12vmin;
    line-height: 10.667vmin;
    letter-spacing: -0.400vmin; }

  // Medium size
  &.size-0 {
    font-size: 6.667vmin;
    line-height: 6.667vmin;
    letter-spacing: -0.100vmin; }

  // Small size (rounded)
  &.font-1 {
    font-family: rounded;
    font-size: 4vmin;
    line-height: 4vmin;
    letter-spacing: -0.027vmin; }

  &.uppercase {
    text-transform: uppercase; }

  // Links
  a {
    text-decoration: none;
    color: #8000FF;
    @media (prefers-color-scheme: dark) {
      color: #99FFEE; } } }



/*  Modal + Lightbox  */

.Modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

.Lightbox {
  background-color: rgba(0, 0, 0, 1);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img, video {
    max-width: 100%;
    max-height: 100%; } }

body.lightbox-active {
  overflow: hidden; }



/* Error Screen */

.ErrorScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
